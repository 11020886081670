@use '../../base/variables' as vars;

$thead-bg-color : vars.$primary-color;
$thead-color : vars.$white;

#body{
   
    .e-grid {
        
        .e-gridheader{
            border: none;
        }
        
        // Column Header - container
        .e-headercelldiv{
            margin-left:-2px;
            padding-left:0px;

            &:first-child{
                margin-left: 0px;
            }
        }


        // Column Header
        .e-headercell,
        .e-detailheadercell{
            // reduce padding
            padding-left:8px; 
            padding-right:4px;
            background-color: $thead-bg-color;

            .e-headertext{
                color:$thead-color;            
            }
    
            .e-icons{
                color: white;
            }
        }

        /* smaller headers are often  needed, override wrap behavior */
        .e-columnheader .e-headercelldiv{
            font-size: 14px;
            text-align: left !important;
            white-space: nowrap;
            text-overflow: ellipsis;
            color:$thead-color;
        }


        // compact 
        &.compact {
            .e-headercell{
                padding-right: 0;
            }
            .e-headercelldiv
            {
              padding-right:0px;
              text-overflow : none;
            }
        } 
          
        &.wrap-headers .e-headertext{
            white-space: normal;
        }
          


    }
}