
// Column Styling - Links, Buttons
.link-column,
.icon-column{
    text-align: right;
}

.e-headercell.link-column .e-headercelldiv,
.e-headercell.icon-column .e-headercelldiv{
    text-align: right;
    padding-right: 10px;
}

.link-column:not(.e-headercell){
    width:100%;
    > div.e-unboundcelldiv {
        display: flex !important; /* override inline style */
        justify-content: flex-end;
    }
}



// Column style - .grid-pre.compact
.grid-pre.compact{
    overflow-y:auto;
    line-height: 1.15em;
    font-size:1em;
    margin:0;
    padding:2px;
    white-space: pre-wrap;

    &.h-125{
        max-height: 125px; 
    }
}



// Column Styling - Highlights & Emphasis

.green-highlight{
    background-color: #ceffc8 !important;

    &:hover > td {
    background-color: darken(#ceffc8, 10%) !important;
    } 
}

.yellow-highlight {
    background-color: #fffac8 !important;

    &:hover>td {
        background-color: darken(#fffac8, 10%) !important;
    }
}


/* highlighted column */
.yellow-bold-column:not(.e-headercell){
    background-color: #ffff66;
    font-weight: bold;
}

.red-highlight {
    background-color: #ffc8ce !important;

    &:hover>td {
        background-color: darken(#ffc8ce, 10%) !important;
    }
}

