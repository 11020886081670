@use '../../base/variables' as vars;

#body ejs-grid .e-toolbar{
    
    border-top: 6px solid vars.$accent-color;
    
    .e-toolbar-item.e-search-wrapper{
        .e-input-group.e-search{
            border-width: 0px;
        }

        // hide search clear! 
        // alernative : instead of hiding, subscribe to click events on it & trigger debounced search
        .e-sicon.e-clear-icon{
            display: none;
        }
    } 

    background-color: vars.$white;
    .e-toolbar-items{
        background-color: vars.$white;
    }

    .e-toolbar-item {
        .e-icons{
            display: none;
        }
        button.e-tbar-btn{
            padding:1px 20px;
            min-height: auto;
            .e-tbar-btn-text{
                font-family: "Graphik-Regular";
                font-size :1rem;
                padding: 0px;
            }
        }
    }
    
    .e-toolbar-item:not(.e-overlay){// not disabled
        button.e-tbar-btn, button.e-tbar-btn:focus{
            background: transparent;
            transition: 0.3s;
    
            border: 1px solid vars.$primary-color;

            .e-tbar-btn-text{
                color: vars.$primary-color;
            }
            .e-btn-icon{
                color: vars.$primary-color;
            }
            
            &:hover {
                background: vars.$primary-color;

                .e-tbar-btn-text{
                    color: $white;
                }    
                .e-btn-icon{
                    color: $white;
                }
            }

            
    
            
            
        }
    }

    .e-toolbar-item.e-overlay{
        button.e-tbar-btn{
            border:1px solid vars.$primary-color;
            background-color: transparent;
        }
    }
  

}
/* 
    background: transparent;
    transition: 0.3s;

    &.btnPrim {
        color: $primaryColor1;
        border: 1px solid $primaryColor1;

        &:hover {
            background: $primaryColor1;
            color: $white;
        }
    }
*/