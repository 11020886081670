@use '../variables' as vars;

.graphik-Medium {
    font-family: 'Graphik-Medium' !important;
}

.graphik-Semibold {
    font-family: 'Graphik-Semibold' !important;
}

.graphik-Bold {
    font-family: 'Graphik-Bold' !important;
}

.commonShadow {
    box-shadow: 3px 3px 5px 0px rgba(50, 50, 50, 0.09);
}

// common css end


* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: none;
    border: none;
}

a {
    text-decoration: none;
}

a:hover {
    transition: all 0.3s ease-out 0s;
}

p {
    font-size: vars.$font-size;
    font-weight: 400;
    line-height: 18px;
    color: vars.$font-color;
    margin-bottom: 0;
}

html {
    overflow-x: hidden;
    scroll-behavior: smooth;
}

body {
    font-family: vars.$font-family;
    font-weight: 400;
    font-size: vars.$font-size;
    line-height: 1.5;
    color: vars.$font-color;
    background-color: vars.$background-color;
}

ul,
ol {
    padding: 0;
    margin: 0px;
    list-style: none;
}

input {
    &::-webkit-input-placeholder {
        opacity: 0.5;
    }

    &:-moz-placeholder {
        opacity: 0.5;
    }
}

.wrapper {
    position: relative;
    width: 100%;
}

.show2::after {
    transform: rotate(180deg);
}

.toast-container {
    left: 0;
    right: 0;
    margin: auto;
    z-index: 9999;
}
.toast-container .ngx-toastr {
    width: 360px !important;
}

.pipBoxScroll {
    min-width: max-content;
}

/* width */
::-webkit-scrollbar {
    height: 5px;
    width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 3px #9fb1d5; 
  border-radius: 3px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #b8c8e7; 
  border-radius: 3px;
}

input::-webkit-contacts-auto-fill-button{
    visibility: hidden;
    display: none !important;
    pointer-events: none;
    position: absolute;
    right: 0;
  }
