
.nowrap{
    white-space: nowrap;
}

.label{
    font-weight: bold;
}


// todo : generate these or replace with bootstrap classes
// width sizing, mostly for grid or table use
.w-1 { width: 1em; }
.w-2 { width: 2em; }
.w-4 { width: 4em; }
.w-6 { width: 6em; }
.w-8 { width: 8em; }
.w-10{ width: 10em; }
.w-12{ width: 12em; }
.w-14{ width: 14em; }

// mostly for button use
.w25{ width:25px;  min-width: 25px; }
.w75 { width:75px;  min-width: 75px;}
.w125{ width:125px; min-width: 125px;}
.w100{ width:100px; min-width: 100px;}
.w150{ width:150px; min-width: 150px;}
.w200{ width:200px; min-width: 200px;}
.w250{ width:250px; min-width: 250px;}

#body {
    .wfb100{ flex-basis: 100px; }
    .wfb200{ flex-basis: 200px; }
}
// probably a better way of doing this -> look for margin utility class AND display utility classes
// split into two, look for utility class in bootstrap
.m-tb-b { margin: 4px 0; display: block;}

// margin
.m22 { margin-top: 22px; }
.m15{ margin:15px;}
.m5{ margin:5px}
.m15{ margin:15px;}
.m20{ margin:20px;}
.mt50 { margin-top : 50px }
.mt10 { margin-top : 10px;}
.mt10 { margin-top : 20px;}
.mtn10 { margin-top : -10px;}
.mr5{ margin-right:5px;}
.mr15{ margin-right:15px;}
.mr25{ margin-right:25px;}
.mr50{ margin-right:50px;}
.mr100{ margin-right:100px;}


// height
.h20{ height: 20px;}
.h30{ height: 30px;}
.h40{ height: 40px;}
.h60{ height: 60px;}

// line height
.lh20{  line-height: 20px; }
.lh40{  line-height: 40px; }






.magic-flex{
    align-items: center;
    justify-content: flex-start ;
    display: inline-flex;
}

.hide{
    display: none !important;
}

.large-font{
    font-size: 14px;
    font-weight:normal
}

.small-font{
    font-size: 14px;font-weight:normal
}

.bold-font{
    font-size:14px;
    font-weight:600;
    display:inline;
}

.btn-centre{
    display:block;
    text-align:center;
}

.hover-container { position: relative;}
.hover-target{ display : none ; position: absolute; bottom: 0; right : 0;}
.hover-show-target + .hover-target { display : block;} 


// claim reassign checkbox, style is tbd...
// .diary .form-group-inline .label-line {
//     flex-basis: 184px !important;
// }
// file is probably src\app\features\claim-reassign\main\main.component.html

.rotate-0{
    transform: rotate(0);
    transition: transform .3s ease-in;
}

.rotate-180{
    transform: rotate(180deg);
    transition: transform .3s ease-in;
}


.cursor-pointer{
    cursor: pointer;
}

.ng-dropdown-panel {
    z-index: 2021 !important;
}

.claimSummBox {
    div {
        text-align: center;

        &:hover {
                background-color: #f8f9f9;
                transition: 0.5s;
        }
    }
}

.add-Hidden {
    display: none;
}

.radBtn .e-radio-wrapper {
    width: 50%;
}
.negotStrat .label-line {
    padding-bottom: 0.25rem !important;
}

.error-container {
    flex: 1;
    display: flex;
    align-items: flex-start;
}

.error-message {
    color: red;
    font-size: 14px;
    margin-top: 5px;
}


