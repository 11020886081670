@use '../base/variables.scss' as vars;

#body{ //specificity hack



    // style dialog, or contents thereof

    ejs-dialog, .e-dialog{
        max-height: 98vh !important; 
        border-top:3px solid vars.$accent-color;
        border-radius:3px;
        border-left:none;
        border-right:none;
        border-bottom: none;
    }

    .e-dialog{

        &.auto-width-modal{
            width: fit-content;
        }

        &.small-modal{
            width:25%;
            max-width: 800px;
        }
      
        &.medium-modal{
            width:60%;
            min-width: 700px;
            max-width: 800px;
        }

        &.wide-modal{
            width:80%;
            max-width: 900px;
        }

        &.page-modal{
            width:95%;
            max-width: 1200px;
            height:98vh;
            max-height: 1000px !important;

            .e-dlg-content { 
                overflow-x : auto;
                > div { width : 99%; }
            }
            
        }

        &.long-modal{
            width: fit-content;
            height: 48%;
           
           
            // @media (min-width:1025px) {
              
            //         height: 60%;
            
            // }
        
            
        }
        // PAGE SPECIFIC MODALS - THESE ARE MOSTLY? FOR THE CLAIM MENU
        &.tall-modal{
            height:80vh;
            max-height: 1200px !important;
            width: 700px;
        }

        &.extra-tall-modal{
            height:95vh;
            max-height: 1200px !important;
            width: 700px;
        }

        .e-dlg-content{
            font-size: 14px;          
        }

        &.modal-no-padding .e-dlg-content{
            padding:0px;
        }

        .e-dlg-header{
            font-size:18px;
            font-family: 'Graphik-Semibold';
            color : vars.$title-color
        }

        .e-dlg-header-content{
            padding-left:15px; // match section indent
        }

        .e-footer-content button{
            min-width: 100px;
        }
    }


    // style edit grid dialog - force two column layout
    .grid-edit-form-two-columns{

        .e-table {
            width: 100% 
        }

        tbody { 
         display: flex; flex-flow: wrap; flex-direction: column; max-height: 400px; align-items: center;
        }

        .e-tooltip-wrap.e-lib.e-control.e-popup.e-griderror {
            transform: translateY(50px);
        }
    
    }
    // .e-icon-btn{
    //     display: none;
    // }
}