#body {
    .e-calendar.bordered {
        .e-content.e-month td,
        th {
            border-bottom: 1px solid darkgrey;
            border-right: 1px solid darkgrey;
        }

        .e-content.e-month td:first-of-type,
        th:first-of-type {
            border-left: 1px solid darkgrey;
        }

        .e-content.e-month tr:first-of-type th
        {
            border-top: 1px solid darkgrey;
        }
    }

    .e-calendar tbody{
        animation: none;
    }

    .e-calendar-day-header-lg {
        .e-cell.e-other-month,
        .e-week-header th
        {
            background-color: whitesmoke;
        }
    }


    .diary-month-calendar .e-focused-date .e-day{
        background-color: #0069d9; // when navigate via url, the day is merely focused and not selected
        color: #fff;
    }

    $background : white;// #263137;
    $hover : #e743c3;
    $today : #007bff;
    $font : #333;// whitesmoke;
    $accent :$hover;
    $border:#E4E7EC;

    .side-bar-calendar.e-calendar{
        
        width: 100%;
        max-width: 100%;
        border-radius: 0;
        border: 0;
        padding-left:-2px;
        padding-top : 2px;
        height:170px;
        background-color : $background;
        border-bottom:1px solid $border;

        .e-header{

            background-color:  $background;

            &.e-month{
                padding:1px;
                height: 20px;
            }
      
            .e-title{
                font-size: 14px;
                line-height: 20px;
                color: $font;        
            }

            .e-icon-container .e-prev,
            .e-icon-container .e-next{
                height:20px;                
                background-color: $background;
                color: $font;      
            }

            .e-icon-container .e-icons{
                padding-top:3px;
                padding-bottom: 3px;
                color: $font;      
                border:1px solid transparent;
                
                &:hover{
                    color:$hover;
                    background-color: $background;
                    border:1px solid $hover;
                    border-radius: 3px;
                }
            }
        }

        .e-other-month{
            visibility: hidden;
            pointer-events: none;
        }

        table{
            background-color:  $background;
            color :$font;
        }

        th, td{
            height:20px;
            font-size:14px;
            background-color:  $background;
            color :$font;

            .e-day{
                height: 20px; 
                line-height: 18px;
                font-size:14px;
                border:1px solid transparent;
                background-color:  $background;
                color :$font;            

            }

            &.e-today .e-day{
                border:1px solid $today;
            }

            &.accent-on-date .e-day{
                color : $accent;
                font-weight: bold;
            }
        }
        
        td:hover{
            .e-day, &.e-today .e-day{
                border: 1px solid $hover;
            }
        }
        
        .e-content table{
            padding:1px;
            padding-bottom: 3px;
        }


    }
}
