// most card styles are provided from bootstrap
@use '../base/variables.scss' as vars;


.card{
  margin-bottom: 1em;
  border:none;
  border-radius: 1px;

  &.accent-top{
    border-top: 3px solid vars.$accent-color;
  }
}

.card-header{
    position: relative;
    display:block;
    width: 100%;
    background-color: transparent;
    font-family: 'Graphik-Semibold';

   &.card-title {
      color : vars.$title-color;
      font-size: 14px;
      padding: 15px;
      border-bottom: 1px solid vars.$border-color;
   }

}

card-header.card-header .row{
  margin-bottom: 0;
  align-items: center;
}

.card > .card-section-header{
   padding-left:15px;
}

.card-section-header{
    font-size: 14px;
    font-weight: bold;
    font-family: 'Graphik-Semibold';
    padding-top: 1.5rem;
    padding-bottom: 1rem;
    width: 100%;
    display: block;
    position: relative;
    margin-bottom: 5px;
    color : vars.$title-color;
    
    &.row{
        margin-left:0;
        margin-right:0;
    }

    .buttons{
        text-align: right;
        margin:-10px
    }
}



.card-section{
    margin: 1.2rem 1.25rem 1.25rem 1.25rem;
    padding:0px;

    &.card-section-no-padding{
      margin:0;
      padding:0;
    }

}


// Sections after title -> reduce margin
.card-section-header + .card-section{
  margin-top: -.25rem;
}

// Card sections 2...n have seperation border on top
.card-section + .card-section{
  border-top: 1px solid vars.$border-color;
}

// Card sections header following a section also has border on top
.card-section + .card-section-header {
  border-top: 1px solid vars.$border-color;

}

.card-footer{
  margin-top:1.5rem;
  border: none;
  background-color: transparent;
}


.card-header.collapsible,
.card-section-header.collapsible{
    padding-right:30px;
}

// ?
// /* collapsible section headers must be blaced above th */
// .card-section-header.collapsible{
//   width: calc(100% - 15px);
//   margin-left: 15px;
// }


.card-header.collapsed ~ .card-section ,
.card-header.collapsed ~ .card-section-header 
{
  height : 0px;
  max-height:0px;
  padding : 0px;
  margin : 0px;
  display: none;
}

.card-section-header.collapsed + .card-section 
{
  height : 0px;
  max-height:0px;
  padding : 0px;
  margin : 0px;
  display: none;
}

.card-collapsible-icon-container{
  position: absolute;
  top: .25rem; // to compensate for padding of (1.5rem - 1.0rem) / 2
  right: 10px;
  bottom: 0;
  display: inline-flex;
  align-items: center;

}

/* no border when inside a modal popup */
.e-dlg-content .card{
  border : none;
}


.mini-card{
  background-color: vars.$background-color;
  font-size: 14px;

  .mini-card-header{
    color : vars.$title-color;
    font-size: 14px;
    font-family: 'Graphik-Medium';
    border-bottom: 1px solid vars.$border-color;
    padding: 10px 10px 5px 10px;
  }

  .mini-card-body{
    padding:5px 10px;
    color : vars.$title-color;
  }

  .row{
    padding-bottom:4px;
  }

}

.title-question{
  font-family: 'Graphik-Regular';
  display: inline-flex;
  align-items: center;
  width: 100%;

  .t{
    white-space: nowrap;
    justify-self: flex-end;
    order: 3;
    margin-left: auto;
    font-weight: normal;
    color: lightgrey;
  }

  .q{
  }
}

.insTitle {
  color: #466295;
  font-size: 14px;
  border-bottom: 1px solid #E4E7EC;
  font-family: 'Graphik-Regular';
}
