// syncfusion accordion over-rides

#body{ 

  .e-accordion{
    background : none;
    border:none;
  }

  .e-acrdn-header{
    border-bottom: 2px solid rgba(0, 0, 0, 0.13);
    border-radius: 0;
    background:none;
  }

  .e-acrdn-header-content{
    text-transform: uppercase;
    font-weight: bold;
    font-size: 1em;
    color:#333;
    text-decoration:none;
  }

  .e-acrdn-item {
    margin-top:20px;
    border: none; 
    background:none;
  }

  .e-acrdn-content{
    background:none;
    padding:5px;
    border:none;
  }

  .bodilCont {
    .e-acrdn-header {
      background-color: #fff;
      margin: 0;
      border-bottom: 1px solid rgba(0, 0, 0, 0.13);
    }
    .e-acrdn-content {
      padding: 0;
    }
  }
}