@use 'variables' as vars;

#body{


.btn{
    border-radius: 4px;
}

.btn-primary-outline{
    color: vars.$primary-color;
    border: 1px solid vars.$primary-color;
    background-color: vars.$white;

    &:hover {
        background: vars.$primary-color;
        color: vars.$white;
    }
}


.btn-secondary-outline{
    color: vars.$secondary-color;
    border: 1px solid vars.$secondary-color;
    background-color: vars.$white;

    &:hover {
        background: vars.$secondary-color;
        color: $white;
    }
}

/* 
// todo - determine how to integrate these styles, and on what buttons to apply!


// $primaryColor2: #44546a; //P
// $primaryColor3: #466295; //inner heading
// $primaryColor5:#ff6565; //delete button
// $primaryColor6:#646464;
// $primaryColor7:#64c879;
// $primaryColor8:#ff9a4e;
// $primaryColor10:#e9ecef;
// $primaryColor11:#41f1c3;
// $primaryColor12:red;

// $backgroundColor1:#eff4fe; //body color
// $backgroundColor2:#ccc;
// $backgroundColor3:#f3f6ffc4;
// $backgroundColor4:#ffdbdb;
// $backgroundColor5:#fafafa;
// $backgroundColor6:#e8f0fe;
// $commonfontFamily: 'Graphik-Regular';
// $commonfontSize: 14px;



.btnStyle {
    text-align: center;
    font-size: 14px;
    font-weight: 600;
    line-height: 30px;
    border-radius: 4px;
    background: transparent;
    padding: 4px 35px;
    text-transform: uppercase;
    cursor: pointer;
    transition: 0.3s;
    margin-right: 10px;

    &.btnPrim {
        color: $primaryColor1;
        border: 1px solid $primaryColor1;

        &:hover {
            background: $primaryColor1;
            color: $white;
        }
    }

    &.btnSecon {
        background: $primaryColor1;
        color: $white;
        border: 1px solid $primaryColor1;

        &:hover {
            background: transparent;
            color: $primaryColor1;
        }
    }

    &.btnReject {
        color: $primaryColor4;
        border: 1px solid $primaryColor4;

        &:hover {
            background: $primaryColor4;
            color: $white;
        }
    }

    &.btnRejectBG {
        background: $primaryColor4;
        color: $white;
        border: 1px solid $primaryColor4;

        &:hover {
            color: $primaryColor4;
            background: transparent;
            border: 1px solid $primaryColor4;
        }
    }

    &.btnClose {
        color: $primaryColor5 !important;
        border: 1px solid $primaryColor5 !important;

        &:hover {
            background: $primaryColor5 !important;
            color: $white !important;
        }
    }

    &.btnCloseBG {
        color: $white;
        border: 1px solid $primaryColor5;
        background: $primaryColor5;

        &:hover {
            background: transparent;
            color: $primaryColor5;
        }
    }

    &.btngreen {
        background: $primaryColor7;
        color: $white;
        border: 1px solid $primaryColor7;

        &:hover {
            background: transparent;
            color: $primaryColor7;
        }
    }

    &.btngreenBG {
        color: $primaryColor7;
        border: 1px solid $primaryColor7;

        &:hover {
            background: $primaryColor7;
            color: $white;
        }
    }

    &.btncogreen {
        background: $primaryColor11;
        color: $primaryColor2;
        border: 1px solid $primaryColor11;

        &:hover {
            background: transparent;
            color: $primaryColor11;
        }
    }

    &.btndisbl {
        background: $primaryColor10;
        color: $white;
        border: 1px solid $primaryColor10;
    }
}

*/
// button end
}
