@use '../variables.scss' as vars;

#body{ // secificity hack

/* 
    
goals : 
    remove focus cues
    uniform color input
    uniform error styling

*/
    
    
    
    /// INPUT SETTINGS
    
    // default
    input,
    textarea,
    .form-control,
    .select,
    .form-select,
    
    // and when focused
    input:focus,
    textarea:focus,
    .form-control:focus,
    .form-switch .form-check-input:focus,
    .form-check-input:focus,
    
    // default - add-ons
    .input-group-text,
    
    // syncfusion too
    .e-input-group,
    .e-input-group .e-input,
    
    // syncfusion too - when focused
    .e-input-group.e-input-focus,
    
    // syncfusion controls - add on icons
    .e-input-group .e-input-group-icon,
    .e-input-group.e-input-focus,
    
    //vendor controls
    .ng-select-container
    {
        box-shadow: none;
        outline: none;
        color : vars.$input-color;
        border-color:vars.$input-border-color;
        background-color: vars.$input-background;
        border-radius: 0;
        opacity: 1;
    }
    
    input,
    textarea{
        font-family: "Graphik-Regular";
    }
    
    
    
    
    /// INPUT DISABLED SETTINGS
    
    // default
    input:disabled,
    textarea:disabled,
    .form-control:disabled,
    .input-group.is-disabled .input-group-text,
    
    // syncfusion too
    .e-input-group.e-disabled,
    .e-input-group.e-disabled .e-input,
    .e-input-group.e-disabled .e-input-group-icon,
    
    //vendor controls
    .ng-select-disabled .ng-select-container
    {
        color : vars.$input-color-disabled;
        background-color: vars.$input-background-disabled;
        border-color:vars.$input-border-disabled;
        cursor: not-allowed;

        // hide placeholder on disabled fields
        ::placeholder{
            color:transparent;
        }
        &::placeholder{
            color:transparent;
        }
    }
    
    
    // Input Borders - transaparent default
    textarea,
    text-input input,
    drop-down ng-select,
    .input-group,    
    
    // syncfusion controls too
    ejs-datepicker .e-input-group,
    ejs-timepicker .e-input-group,
    ejs-datetimepicker .e-input-group
    { 
        border: 1px solid transparent;
    }

    
    // Error Styles
    textarea.ng-invalid.ng-touched,
    text-input input.ng-invalid.ng-touched,
    drop-down ng-select.ng-invalid.ng-touched,
    .input-group.ng-invalid.ng-touched,
    
    // syncfusion controls too
    ejs-datepicker.ng-touched.ng-invalid .e-input-group,
    ejs-timepicker.ng-touched.ng-invalid .e-input-group,
    ejs-datetimepicker.ng-touched.ng-invalid .e-input-group
    { 
        border: 1px solid vars.$error-border-color;
    }
    
    print-error {
        color : vars.$error-color;
    }
    
    // datepicker corrections 
    ejs-datepicker, ejs-datetimepicker, ejs-timepicker{
    
        .e-input-group.e-control-wrapper input.e-input{
            // correct font size
            font-size: 14px;
        }
    }
    
    // error inputs - use z-index to put validation error to top
    .input-group .form-control, .input-group ng-select{
        z-index: 10;
    }
    
   
    
    // placeholder styles
    .ng-placeholder, 
    input::placeholder,
    textarea::placeholder { 
        color: vars.$input-color-placeholder;
        opacity: 1; 
    }

    // heights - correct to make uniform with syncfusion controls
    input.form-control, .input-group-text{
        height:32px;
    }

}
    