@use '../variables.scss' as vars;

$height:32px;

#body
{
    // mimic form control 100% width settings
    .ng-select{
         width:100%
    }

    // force dropdown to fit items ( for large dropdowns no elipsis )
    .ng-dropdown-panel { 
        width: auto !important; 
        min-width: 100%;
    }   


    // sometimes really want the width to be 100pct of parent, regaurdless of item size
    .ng-select.ng-dropdown-panel-width-100pct{
        .ng-dropdown-panel { 
            width:100% !important
        }   
    }


    // override width when in input group
    .input-group > .ng-select:not(:last-child){  
        width: auto;
        flex-grow: 2;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        max-width: calc( 100% - 3em);

        .ng-select-container {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }
    }

    .ng-dropdown-panel.ng-select-bottom{
        z-index: 9000 !important;
    }
    // default setting below...

    .ng-select .ng-select-container {   
        min-height: $height;
    }
    .ng-select .ng-select-container .ng-input {
        line-height: 22px;
    }
    
    
    .ng-select.ng-select-single .ng-select-container {
        height: $height;
    }
    
    
    .ng-select.ng-select-multiple .ng-select-container {
        min-height: $height;
    }
    

    .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
        background-color: white;
        height: 24px;
        line-height: 22px;
    }

    .ng-select.ng-select-multiple.ng-select-disabled .ng-select-container .ng-value-container .ng-value{
        color : vars.$input-color-disabled;
    }

    .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-input {
        height: 24px;
        margin-top: 3px;
    }
    
    .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon.left{
        border-right-color: vars.$border-color;
    }


    .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-placeholder {
        height: 20px;
    }
    
    .ng-select .ng-clear-wrapper {
        height: 14px;
    }
    
    .ng-select .ng-select-container {
        min-height: $height;
    }
    
    .ng-select .ng-select-container .ng-input {
        line-height: 18px;
    }
    
    .ng-select .ng-arrow-wrapper {
        height: 26px;
        top: 4px;
    }

    .ng-select-container, .ng-dropdown-panel-items,
    .ng-dropdown-panel .ng-dropdown-panel-items .ng-option
    {
        color : rgb(73, 80, 87)
    }

    .ng-select-container .ng-input > input{
        background-color: transparent;
    }
}