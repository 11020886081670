

 #body{ //  specificity hack

    label-spacer + button{
        margin-top:1px;
    }

    // PUT MODAL STYLE BUTTONS INSIDE MODAL!
   
    // modal footer buttons : eg, save cancel
    // .e-dialog .e-footer-content .e-btn{

    //     // default
    //     background: white;
    //     color: #555;    
    //     min-width:100px;
    //     margin-left:20px;
    //     padding-left:20px;
    //     padding-right:20px;

    //     // primary
    //     &.e-primary:not(:disabled){
    //         background-color: rgb(231, 76, 60);
    //         color:white;
    //         border: 0;
            
    //         &:hover, &:focus {
    //             background-color:rgba(188, 61, 48, 1);
    //         }
    //     }

    // }

    // button.btn.btn-primary:not(:disabled){
    //     background-color: rgb(231, 76, 60);
    //     border: 0;
        
    //     &:hover, &:focus {
    //         background-color:rgba(188, 61, 48, 1);
    //     }
    // }

    // .e-btn.e-primary:disabled, // primary button
    // .e-link.e-active:disabled, // paging buttons
    // button.btn.btn-primary:disabled
    // {
    //     background-color: rgba(0, 131, 122, 1.0);
    //     opacity: .4;
    //     cursor:not-allowed;
    // }


    // PUT GRID, GRID PAGING, OTHER GRID BUTTON STYLES INSIDE GRID!
   
    // grid - link buttons
    // .btn-link{
    //     color: #e74c3c;

    //     &:hover{
    //         color: darken(#e74c3c, 10%) 
    //     }

    //     &:disabled, &.disabled{
    //         color :#6c757d;
    //         pointer-events: none;
    //     }

    // }

     // grid - command link column
    //  .e-grid .e-link.e-btn{
    //     color: #e74c3c;
    //     font-size: 1rem;
    //     margin:0;
    //     padding:5px 9px;
        
    //     &:hover, &:focus {
    //         color: rgb(51, 51, 51);
    //     }

    //     &:disabled, &.disabled{
    //         color :#6c757d;
    //         pointer-events: none;
    //     }
    // }


  

     // paging buttons
    // .e-pager .e-link, .e-pager .e-icons
    // {
    //     // default
    //     background-color: transparent;
    //     color: #333;    

    //     // active 
    //     &.e-currentitem.e-active:not(:disabled){
    //         background-color: rgb(231, 76, 60);
    //         border: 0;
    //         color: white;    
            
    //         &:hover, &:focus {
    //             background-color:rgba(188, 61, 48, 1);
    //         }
    //     }
    // }

    // paging background
    // .e-pager .e-pagercontainer{
    //     background-color: transparent;
    // }

    // grid - toolbar buttons

    // .e-tbar-btn {

    //     color: white;

    //     background-color: rgb(231, 76, 60);
        
    //     .e-btn-icon{

    //         color: white;

    //     }

    // }

}