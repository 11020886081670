// @import './../base/variable' as vars;
/* icon styles 
--
 these are for use with the grid icons

 and

 these are heavily used in the iconComponent
 
*/

$icon-background : #eff4fe;// #DEE9FE;
$icon-color : #4987FF;


#body { // specificity hack

    
// Grid Icon Styles, do a special style
.e-grid .img-icon.fa, .e-grid .img-icon.e-icons{
    color  :$icon-color;
    background-color : $icon-background; 
    border-radius: 0;
}

.img-icon.fa, .img-icon.e-icons, .img-icon{
    font-size:1.3rem;
    color  :$icon-color;
    margin-top:0;
    margin-left:0;
}

    
@mixin icon-bg( $img ){
    background-image: url( '/assets/images/buttons/' + $img );
    background-position: center,center;
    background-repeat: no-repeat;
}


.img-icon-host{
    border-radius: 0;
    margin: 1px;
    margin-right:5px;
    display: inline-flex;
    padding: 0;
    border: 0;

    &.e-hide{
        display: none;
    }
}


.img-icon.cursor-default{
    cursor:default;
}

.img-icon.transparent, .img-icon.transparent.fa{
    background-color: transparent;
}


.img-icon {
    width: 32px;
    height: 32px;
    line-height: 32px;
    border: none;
    transition: opacity 0.2s ease-in-out;
    border-radius: 2px;
    background-color:rgba(255, 255, 255, 0.8);

  
    // grid icon buttons
    &.e-btn{
        border-radius: 5px;
        margin-left:1px;
        padding-left:9px;
        padding-right:9px;
        margin-right:2px;
        width: 34px;

        //tbd - is this used anywhere?
        // &:disabled{
        //     @include icon-bg('dis.gif');
        // }
    }
}


/// THESE ARE CLASES FOR USE WITH THE ICON BUTTON COMPOENNT
/// move to icon-button.scss

/// ICON BUTTON COMPONENT


.icon-button-host{
    &.disable > button{
         cursor:default;
    }

    > button.transparent{
        background-color: transparent;
    }

    > button {
        > i{
            display: block;
        }

        > i.icon-image{
            width: 100%;
            height: 100%;
            display: block;
            background-size:contain;  
            border: none; 
        }
    
    }
}

// xs icons
.icon-button-xs {
    width: 16px;
    height: 16px;
    > i{
        font-size: .8rem;
    }
}

// medium icons
.icon-button-m {
    width: 29px;
    height: 29px;

    > i{
        font-size: 1.7rem;
    }
    
}

i.scanned-documents-empty{
    @include icon-bg('infothekdesk-empty.svg');
}
i.scanned-documents-full{
    @include icon-bg('infothekdesk.svg');
}

.image-right{
    @include icon-bg('IRQuickLaunch.png');
}

//user profile image / icon
.user-image{
    background-image:url('/assets/images/buttons/person.svg');
    background-repeat:no-repeat;
    background-size: 100%;
    height:30px;
    width:30px;
    border-radius: 50%;
}


// menu icons
.header-container i {
    font-size:2rem;
}

//default icon size
i{
    font-size: 1.2rem;
}

i.small-font{
    font-size: 1rem;
}

// end #body selector
}