/* 
These are styles to over-ride the default syncfusion grid

To change more grid proerties, like color, consider changing the theme variables or see
https://ej2.syncfusion.com/angular/documentation/grid/style-and-appearance/


*/

@use '../../base/variables' as vars;
@import 'toolbar';
@import 'header';
@import 'body';
@import 'pager';
@import 'column-styles';



.e-grid {
    border: none;
}