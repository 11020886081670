@use '../variables.scss' as vars;

#body
{
    $switch-off-background : #CCCCCC;
    $switch-on-background: vars.$primary-color;
    
    .e-switch-wrapper{
        width: 35px;
        vertical-align: sub;
    }
    
    .e-switch-wrapper, .e-switch-inner{
        box-shadow: none;
        outline: none;
        border:none;
        width: 50px;
        height: 27px;
    }
    
    .e-switch-off{
        background-color: $switch-off-background;
    }
    
    .e-switch-handle{
        background-color: white;
        height: 14px;
        width: 14px;
    
        &:not(.e-switch-active){
            left: 3px;
        }
    }

    .switch-wrapper.is-disabled{
        cursor:not-allowed;

        .e-switch-wrapper{
            pointer-events: none;
        }
        
        .e-switch-on{
            background-color: scale-color($color: $switch-on-background, $saturation: -60%, $lightness:50%);
        }
        .e-switch-off{
            background-color: scale-color($color:$switch-off-background, $saturation: -80%, $lightness:50%);
        }
    }
    .e-switch-wrapper input[type="checkbox"]{
        appearance: none;
        -webkit-appearance: none;
    } 

}
