
$body : #EFF4FE;

html {
    position: relative; /* required by ng-select */
}

html, body 
{
    margin: 0;
    background-color:  $body;
    font-size: 14px;
}

.page-cover{
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
}

