// specificity hack
#body {
    .e-tooltip-wrap {
        border-radius: 5px;
        .e-tip-content{
            background-color: #37435a;
            font-size: 14px;
            border-radius: 5px;
            padding: 12px;
            label {
                padding-right:5px;
                font-weight: bold;
            }
        }
        .e-arrow-tip-outer {
            &.e-tip-bottom {
                border-top: 8px solid #37435a;
            }
        }
        .e-arrow-tip-inner{
            &.e-tip-bottom {
                color: #37435a
            }
        }
    }
}
.claimStatus {
    background: rgb(255 255 255 / 20%);
    padding: 5px 0;
    margin: 8px 0 0 0;
    color: #19efb6;
    text-align: center;
}
.toolTable td {
    padding: 3px;
    vertical-align: top;
}