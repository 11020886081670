// custom collapsible component
#body{ 


  .ax-collapsible{
    -webkit-transition: max-height 1s; 
    -moz-transition: max-height 1s; 
    -ms-transition: max-height 1s; 
    -o-transition: max-height 1s; 
    transition: max-height 1s;  
    overflow: hidden;
  }

  .ax-collapsible-open{
    max-height:auto;
  }


  .ax-collapsible-collapsed{
    max-height:0;
    padding: 0;
    margin: 0;
  }

}