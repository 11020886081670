/* checkboxes */
label.check-box-wrap{
    padding:10px; margin:-5px;

    input[type=checkbox]{
        width:20px;height:20px;
    }
}

/* radio buttons */
.radio-btn-small .e-radio + label .e-label {
    font-size : 14px;
    padding-left: 18px;
}

.radio-btn-nowrap .e-radio + label .e-label {
    white-space: nowrap;
}

.radio-btn-strong-disabled{
    .e-radio:disabled + label .e-label {
        color:rgba(56, 12, 12, 1);
    }
}

