@use '../base/variables' as vars;

// fixed width!
$sidebar-width:250px;

page-split{



    split-main{
        // normal block to allow for title positioned above!
        display: block;
        float: left;
        width:calc( 100% - $sidebar-width);
        padding-right:15px;
    }

    split-sidebar{
        position: sticky;
        float: right;
        top:0;
        margin-top:-300px; // go to top of parent context!
        z-index: 20;
        height: 100%;
        width: $sidebar-width;

        background:transparent;
    }

    .split-sidebar-contents{
        // use relative position to move 
        // move and expand sidebar contents 
        // this eliminates margin space from sticky parent        
        position: relative;
        top:-10px;
        left:10px;
        width: 100%;
        height: calc(100% + 20px);

        background-image: url('/assets/images/pages/sidebar-background.jpg');
        background-repeat: no-repeat;
        background-size: cover;

        display: inline-flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: stretch;
        padding:10px;

        header{
            font-size: 14px;
            color: vars.$title-color;
            font-family: "Graphik-Medium";
            margin-top: 16px;
        }

        .sidebarScroll {
            overflow-y: auto;
            overflow-x: hidden;

            section{
                margin-top:1rem;
                flex-grow:1;
            }
    
            footer{
                margin-bottom: 1rem;
                justify-self: flex-end;
            }
        }

        
        
    }



}
.advnEntry {
    background-color: #ffffff;
    padding: 5px 10px;
    border-top: 3px solid #19efb6;
    border-radius: 4px;
    margin-top: 20px;
    label.headLabel {
        font-family: "Graphik-Semibold";
        color: #466295;
        font-size: 14px;
        padding-bottom: 4px;
        display: block;
    }
}
