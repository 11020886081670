/* You can add global styles to this file, and also import other style files */




// import fontawesome icons ( misc places in app )
// @import "../node_modules/@fortawesome/fontawesome-free/css/fontawesome.css";
@import "../node_modules/font-awesome/css/font-awesome.css";

// @import "../node_modules/@font-awesome/fontawesome-free/css/font-awesome.css";

// import bootstrap icons ( main menu sidebar )
@import '~bootstrap-icons/font/bootstrap-icons';

// import bootstrap 5
@import "../node_modules/bootstrap/dist/css/bootstrap.css";

// import cogigate boota
// import cogigate bootstrap theme... ( todo... ) tbd if they are the same!

// import all synfusion theming for bootstrap 3/4?
// @import "../node_modules/@syncfusion/ej2-bootstrap-theme/styles/bootstrap.css";

// synfusion vendor
@import '../node_modules/@syncfusion/ej2-angular-calendars/styles/bootstrap5.css';
@import '../node_modules/@syncfusion/ej2-angular-grids/styles/bootstrap5.scss';
@import '../node_modules/@syncfusion/ej2-angular-popups/styles/bootstrap5.css';
@import '../node_modules/@syncfusion/ej2-base/styles/bootstrap5.scss';
@import '../node_modules/@syncfusion/ej2-buttons/styles/bootstrap5.scss';
@import '../node_modules/@syncfusion/ej2-calendars/styles/bootstrap5.scss';
@import '../node_modules/@syncfusion/ej2-dropdowns/styles/bootstrap5.scss';
@import '../node_modules/@syncfusion/ej2-icons/styles/bootstrap5.css';
@import '../node_modules/@syncfusion/ej2-inputs/styles/bootstrap5.scss';
@import '../node_modules/@syncfusion/ej2-layouts/styles/bootstrap5.scss';
@import '../node_modules/@syncfusion/ej2-navigations/styles/bootstrap5.scss';
@import '../node_modules/@syncfusion/ej2-notifications/styles/bootstrap5.css';
@import '../node_modules/@syncfusion/ej2-pdfviewer/styles/bootstrap5.css';
@import '../node_modules/@syncfusion/ej2-popups/styles/bootstrap5.scss';
@import '../node_modules/@syncfusion/ej2-splitbuttons/styles/bootstrap5.scss';
@import '../node_modules/@syncfusion/ej2/base/bootstrap5.scss';
@import '../node_modules/@syncfusion/ej2-angular-documenteditor/styles/bootstrap5.scss';
// @import "../node_modules/@syncfusion/ej2/material.css";

// other vendors
@import "~@ng-select/ng-select/themes/default.theme.css";


// overrides & custom
@import 'styles/base/base';
@import 'styles/components/components';

// print styles
@import 'styles/print/print';
