
@font-face {
    font-family: 'Graphik-Bold';
    src: url('../fonts/Graphik-Bold.eot?#iefix') format('embedded-opentype'), url('../fonts/Graphik-Bold.otf') format('opentype'), url('../fonts/Graphik-Bold.woff') format('woff'), url('../fonts/Graphik-Bold.ttf') format('truetype'), url('../fonts/Graphik-Bold.svg#Graphik-Bold') format('svg');
    font-weight: normal;
}

@font-face {
    font-family: 'Graphik-Light';
    src: url('../fonts/Graphik-Light.eot?#iefix') format('embedded-opentype'), url('../fonts/Graphik-Light.woff') format('woff'), url('../fonts/Graphik-Light.ttf') format('truetype'), url('../fonts/Graphik-Light.svg#Graphik-Light') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Graphik-Medium';
    src: url('../fonts/Graphik-Medium.eot?#iefix') format('embedded-opentype'), url('../fonts/Graphik-Medium.otf') format('opentype'), url('../fonts/Graphik-Medium.woff') format('woff'), url('../fonts/Graphik-Medium.ttf') format('truetype'), url('../fonts/Graphik-Medium.svg#Graphik-Medium') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Graphik-Regular';
    src: url('../fonts/Graphik-Regular.eot?#iefix') format('embedded-opentype'), url('../fonts/Graphik-Regular.otf') format('opentype'), url('../fonts/Graphik-Regular.woff') format('woff'), url('../fonts/Graphik-Regular.ttf') format('truetype'), url('../fonts/Graphik-Regular.svg#Graphik-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Graphik-Semibold';
    src: url('../fonts/Graphik-Semibold.eot?#iefix') format('embedded-opentype'), url('../fonts/Graphik-Semibold.otf') format('opentype'), url('../fonts/Graphik-Semibold.woff') format('woff'), url('../fonts/Graphik-Semibold.ttf') format('truetype'), url('../fonts/Graphik-Semibold.svg#Graphik-Semibold') format('svg');
    font-weight: normal;
    font-style: normal;
}