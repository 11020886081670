    
$row-color : #FBFBFB;
$row-stripe-color : $white;

 .e-grid {

    // row striping
    .e-row { background-color : $row-color }
    .e-row.e-altrow{ background-color: $row-stripe-color }



    // cell 
    td.e-rowcell {
        font-size: 14px; 
        padding: 6px;
        border-bottom: none;
        border-top:none;
    }


}