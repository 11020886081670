// vendor - component over-rides,  syncfusion
@import 'accordion';
@import 'calendar';
@import 'card';
@import 'dialog';
@import 'document-editor';
@import 'tooltip';
@import 'grid/grid';
@import 'page-split';

// custom styles, tbd : roll into components?
@import 'detail-table';
@import 'collapsible'; 
@import 'icon';


// tbd... this will get split into parts
@import 'button';
// part 1 -> grid button ovverides ( grid.scss ) 
// part 2 -> grid pager button overrides ( grid.scss )
// part 3 -> base -> base/buttons.scss ? ( link button stuff that goes in grid )


/* to be calendar.scss */
.e-calendar{
    border-radius: 0;
    border:none;
}