
.e-grid{

    .e-gridpager { 
        background-color: white;

        .e-pagercontainer{
            border-radius: none;
        }

        div {
            border: none;
            border-color:transparent;
        }

        .e-numericitem, .e-numericitem.e-focused{
            border-color: transparent;
            box-shadow: none;
        }
    }


}
