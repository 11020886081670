@use '../variables.scss' as vars;

#body{

    // add on buttons

    .input-group .btn,
    .btn.btn-add-on {
    
        border-radius: 0;
        border-color: vars.$input-border-color;
        background-color: vars.$input-background;
        color: vars.$primary-color;
        padding: 0;
        text-align:center; 
        min-width: 3em;
        margin-left:0;

        i {
            font-size: 1.3rem;
            vertical-align: middle;
        }
    
        &:hover {
            color: vars.$input-background;
            background-color: vars.$primary-color;
        }
    }
    

    // for buttons not part a form, add some padding back
    .input-group .btn:not(.btn-add-on){
        padding: 4.5px 9px;
    }


    // add on text
    .input-group-text { 
        text-align:center; 
        min-width: 3em;
        margin-left:0;
    }     

    .input-group.is-disabled .btn.btn-add-on{
        border-color: vars.$input-border-disabled;
        background-color: vars.$input-background-disabled;
        color: vars.$input-color-disabled;
    }
}
    