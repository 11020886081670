/* apply some spacing between form input rows */
form.compact .row, .form.compact .row{
    margin-bottom: 0px;
}

form.compact5 .row, .form.compact5 .row{
    margin-bottom: 5px;
}

form .row, .form .row{
    margin-bottom: 10px;
}



// form-input-labels
.label-line{
    min-height:1.45em;
    // white-space: nowrap;
    font-size:14px;
    font-weight: normal;
}

.label-line .text-muted{
    padding-left: 1em;
}

.label-line-sm .label-line{
    font-size: 14px;
}

.required-text{
    color:red;
    margin:3px;
}


// form-input-groups
.input-group .e-input-group {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
}

// allow syncfusion controls to grow time-picker, date-input
.input-group .e-control{
    flex-grow: 1;
}

.ax-form-group{
    z-index: 1;
}


/* table form 
- a form that looks like a 2 column table
- each row has a label ( column 1 ) and an input value ( column 2 )
- it leans on inline form styles
*/
.table-form .row {
    margin-bottom: 10px;
    border-bottom: 1px solid gainsboro;
    padding-bottom: 5px;
}

/* inline form control styles */
.form-group-inline, .table-form .ax-form-group {
    display: inline-flex;
    width: 100%;

    .label-line{
        flex-basis: 150px;  // <- param this! with inline-over-ride option -> eg : labelStyle = "150w"
        flex-grow: 0;
        flex-shrink: 0;
        padding: 3px 3px 5px 5px;
        white-space : unset;

        .text-muted {
            padding-left: 0; 
            display: block;
        }
    }    
    
    // style width of input
    > div:not(.label-line) {
        width: 100%;
        max-width: calc(100% - 150px); // <- matches above param 

        .input-group{
            flex-wrap: nowrap;
        }
    }

    // first child should be label, this is whn thre is no label
    > div:first-child:not(.label-line){
        max-width: none;
    }

}
