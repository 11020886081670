// default settings + extras
$font-family: 'Graphik-Regular';
$font-family-semibold:'Graphik-Semibold';
$font-size: 14px;
$font-size-small:14px;
$font-size-large:14px;
$font-color : #646464;
$font-muted : #6C757D;
$font-active : #2D75FF;
$background-color:#eff4fe;
$border-color:#E4E7EC;

// colors : 
$primary-color : #3077ff;
$accent-color : #41f1c3;
$title-color: #466295;
$secondary-color:#898f96; // eg: default or reset button

$white : white;

// inputs :
$input-background : #E9ECEF;
$input-color : black;
$input-border-color: $input-background;

$input-background-disabled : scale-color( #F5F6F8, $saturation:-25%);
$input-color-disabled :grey;//#B7B8BB;
$input-border-disabled : $input-background-disabled;

$input-color-placeholder:#AFB0B2;

$error-color:#dc3545;
$error-border-color:$error-color;
